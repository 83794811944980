import _ from 'lodash';
import { PaymentType } from '@wix/wix-vod-constants/dist/api-types/enums';
import CLOSE_REASONS from '../../full-screen-modals/constants/close-reasons';
import { openFullScreenOverlay, openMobileOverlay, } from '../worker/actions/openOverlay';
export { openFullScreenOverlay, openMobileOverlay };
/**
 * Opens full-screen overlay with channel subscription info
 * @param channelId
 * @param onCloseByUser
 * @param isPricingPlan

 */
export function openFullScreenSubscribeOverlay(channelId, onCloseByUser, isPricingPlan) {
    if (onCloseByUser === void 0) { onCloseByUser = _.noop; }
    var paymentType = isPricingPlan
        ? PaymentType.PLAN
        : PaymentType.SUBSCRIPTION;
    return openFullScreenOverlay("#/payment/".concat(paymentType, "/").concat(channelId), function (reason) {
        if (reason === CLOSE_REASONS.CLOSED_BY_USER) {
            onCloseByUser();
        }
    });
}
/**
 * Opens full-screen overlay with video purchase info
 * @param channelId
 * @param videoId
 */
export function openFullScreenPurchaseOverlay(channelId, videoId) {
    return openFullScreenOverlay("#/payment/".concat(PaymentType.PURCHASE, "/").concat(channelId, "/").concat(videoId));
}
/**
 * Opens full-screen overlay with video rent info
 * @param channelId
 * @param videoId
 */
export function openFullScreenRentOverlay(channelId, videoId) {
    return openFullScreenOverlay("#/payment/".concat(PaymentType.RENT, "/").concat(channelId, "/").concat(videoId));
}
/**
 * Opens full-screen overlay with member only video info
 * @param channelId
 * @param videoId
 * @param onClose
 */
export function openFullScreenMemberOnlyOverlay(channelId, videoId) {
    return openFullScreenOverlay("#/payment/".concat(PaymentType.MEMBER_ONLY, "/").concat(channelId, "/").concat(videoId));
}
/**
 * Opens full-screen overlay with channel info
 * @param channelId
 */
export function openFullScreenChannelOverlay(channelId) {
    return openFullScreenOverlay("#/channel/".concat(channelId, "/info"));
}
export function openFullScreenShareOverlay(channelId, videoId) {
    var baseUrl = "#/channel/".concat(channelId, "/share/");
    return openFullScreenOverlay(videoId ? "".concat(baseUrl).concat(videoId) : baseUrl);
}
