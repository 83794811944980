import { __assign } from "tslib";
import _ from 'lodash';
import { durationToISOString } from '../../utils/duration';
import { getResizedImageUrl, MODES, } from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
var getVideoKeywords = function (data) {
    var keywords = [];
    if (data.categories && data.categories.length) {
        keywords.push(data.categories.join(', '));
    }
    if (data.tags && data.tags.length) {
        keywords.push(data.tags.join(', '));
    }
    if (data.crew) {
        data.crew.forEach(function (crewItem) {
            keywords.push(crewItem.role);
            keywords.push(crewItem.name);
        });
    }
    if (data.cast) {
        data.cast.forEach(function (castItem) {
            keywords.push(castItem.role);
            keywords.push(castItem.name);
        });
    }
    return keywords;
};
var getVideoContentUrls = function (data) {
    return {
        embedUrl: data.mediaExternUrl,
    };
};
var getThumbnailUrl = function (url) {
    return getResizedImageUrl({
        url: url,
        height: 330,
        width: 330,
        mode: MODES.fill,
    });
};
var getChannel = function (data) {
    var keywords = [];
    var _a = data.statsInfo || {}, categories = _a.categories, tags = _a.tags;
    if (categories && categories.length) {
        keywords.push(categories.map(function (c) { return c.value; }).join(', '));
    }
    if (tags && tags.length) {
        keywords.push(tags.map(function (c) { return c.value; }).join(', '));
    }
    return {
        '@context': 'http://schema.org',
        '@type': 'MediaObject',
        name: data.title,
        description: data.description || undefined,
        thumbnailUrl: getThumbnailUrl(data.customCoverUrl || data.coverUrl),
        keywords: keywords,
    };
};
var getVideo = function (data) {
    var _a = getVideoContentUrls(data), embedUrl = _a.embedUrl, contentUrl = _a.contentUrl;
    var thumbnailUrl = getThumbnailUrl(data.customCoverUrl || data.posterUrl || data.coverUrl);
    return {
        '@context': 'http://schema.org',
        '@type': 'VideoObject',
        name: data.title,
        thumbnailUrl: thumbnailUrl,
        image: thumbnailUrl,
        height: data.height,
        width: data.width,
        embedUrl: embedUrl,
        contentUrl: contentUrl,
        uploadDate: data.datePublish,
        description: data.description || data.title,
        duration: data.durationSec
            ? durationToISOString(data.durationSec)
            : undefined,
        keywords: getVideoKeywords(data),
    };
};
export var getSeoData = function (_a) {
    var channels = _a.channels, videos = _a.videos, isSingleVideo = _a.isSingleVideo;
    if (isSingleVideo) {
        var video = videos[0];
        return video ? getVideo(video) : null;
    }
    var channelsSeo = channels.map(function (channel) {
        var channelSeo = getChannel(channel);
        var video = videos.filter(function (video) { return _.trim(video.title); }).map(getVideo);
        return __assign(__assign({}, channelSeo), { video: video });
    });
    return channelsSeo[0];
};
